<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar
                      size="large"
                      class="primary"
                      icon="pi pi-user"
                    />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Applicant's Account Details
                    </div>
                    <div class="text-sm text-500">
                      Details of applicant account record
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col comp-grid">
            <div class="">
              <div>
                <div class="relative-position" style="min-height: 100px">
                  <template v-if="!loading && item">
                    <div class="row q-col-gutter-x-md">
                      <div class="col-12">
                        <div class="grid align-items-center">
                          <div class="col">Account Name:</div>
                          <div class="col font-bold">
                            {{ item.account_name }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">Account Username:</div>
                          <div class="col font-bold">
                            {{ item.account_username }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">Account Email:</div>
                          <div class="col font-bold">
                            {{ item.account_email }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">Account Photo:</div>
                          <div class="col font-bold">
                            <image-viewer
                              image-size="large"
                              image-preview-size=""
                              :src="item.account_photo"
                              width="50px"
                              height="50px"
                              :num-display="1"
                            >
                            </image-viewer>
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">Account Status:</div>
                          <div class="col font-bold">
                            {{ item.account_status }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">Last Otp Code:</div>
                          <div class="col font-bold">{{ item.otp_code }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="!loading && !item">
                    <div class="p-3 text-center">
                      <Message
                      severity="error"
                      :closable="false"
                      class="text-left"
                      >We could not find Applicant's Account details. Record must have been deleted from database</Message
                    >
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "subviewApplicantsPage",
  components: {},
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantssubview",
    },
    pagePath: {
      type: String,
      default: "applicants/subview",
    },
    apiPath: {
      type: String,
      default: "applicants/subview",
    },
  },
  data() {
    return {
      item: {
        default: {},
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Applicants Details";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicants/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  methods: {
    ...mapActions("applicants", ["fetchRecord", "deleteRecord"]),
    getActionMenuModel(data) {
      return [];
    },
  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
